/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(window).load(function(){
          $('.angle-down-wrapper i').click(function(e){
            e.preventDefault();
            if ($(window).width() < 781) {
              $(window).scrollTo($('.fp-section:nth-child(3)'), 600);
            } else {
              $.fn.fullpage.moveSectionDown();
            }
          });

          $('.widget_latest_tweets_widget .widget-title').replaceWith('<h1 class="widget-title">' + $('.widget_latest_tweets_widget .widget-title').text() + '</h1><br>');

          $('.tweet-text').each(function(){
            var tweetText = $(this).text();
            if (tweetText.indexOf('RT') > -1) {
              $(this).html($(this).html().replace('RT', ''));
              $(this).closest('li').prepend('<div class="retweet"><i class="fa fa-retweet" aria-hidden="true"></i><span>Blackridge Retweeted</span></div>');
              $(this).wrapInner('<div class="tweet-paragraph">');
              $(this).prepend('<div class="tweet-avatar"><img src="https://unavatar.now.sh/twitter/' + tweetText.substring(tweetText.indexOf('@'), tweetText.indexOf(' ', tweetText.indexOf('@')) - 1) + '"/></div>');
            } else {
              $(this).wrapInner('<div class="tweet-paragraph">');
              $(this).prepend('<div class="tweet-avatar"><img src="https://unavatar.now.sh/twitter/@blackridgetx"/></div>');
            }
          });

          $('.tweet-text a').each(function(){
            if($(this).text().indexOf('pbs.twimg') > -1) {
              var tweetImageSrc = $(this).attr('href');
              $(this).closest('.tweet-text').append('<div class="tweet-image-wrapper"><img src="' + tweetImageSrc + '" class="tweet-image"/></div>');
              $(this).remove();
            }
          });

          $('.tweet-details a time').text($('.tweet-details a time').text().substring(0, $('.tweet-details a time').text().indexOf(',')));

          $('.tweet-details').each(function(){
            var tweetUrl = $(this).first().attr('href');
            $(this).prepend('<div class="like-share"><a href="' + tweetUrl + '"><i class="fa fa-heart" aria-hidden="true"></i></a><a href="' + tweetUrl + '"><i class="fa fa-share-square-o" aria-hidden="true"></i></a></div>');
          });

          if ($(window).width() < 781) {
            var i=0;
            $('.carousel-inner').empty();
            $('.carousel-inner').addClass('latest-tweets');
            $('.latest-tweets ul li:first-child').wrap('<div class="item active"></div>');
            $('.latest-tweets ul li:not(:first-child)').wrap('<div class="item"></div>');

            $('.latest-tweets ul .item').each(function(){
              $('.carousel-inner').append($(this));
            });

            $('.carousel > p').remove();

            $(function() {
              $('.item, .tweet-text, .tweet-image-wrapper').swipe( {
                swipeRight:function(event, direction, distance, duration, fingerCount, fingerData, currentDirection) {
                  $('.carousel').carousel('prev');
                },
                swipeLeft:function(event, direction, distance, duration, fingerCount, fingerData, currentDirection) {
                  $('.carousel').carousel('next');
                }
              });
            });


          }


        });

        $('.dismiss-toggle').click(function(){
          $('.tab-pane.active').removeClass('in');
          $('.tab-pane.active').removeClass('active');

          $('.team-wrapper li').removeClass('active');
        });

        $('a[data-toggle="tab"]').click(function(){
          $('.first-opacity').removeClass('first-opacity');
        });

        var teamMember = $(location).attr('href').substring($(location).attr('href').indexOf('#')+1, $(location).attr('href').length);
        $('.team-wrapper li a').each(function(){
          if($(this).find('h2').text().toLowerCase().indexOf(teamMember) > -1) {
            $(this).trigger('click');
            $(window).scrollTo('.tab-pane.fade.active.in', 600);
          }
        });

        $('.team-nav.next').click(function(){
          if ($('.team-navigation li.active').next('li').length < 1) {
            $('.team-navigation li.active').next().next('li').find('a').first().tab('show');
          } else {
            $('.team-navigation li.active').next('li').find('a').first().tab('show');
          }
        });

        $('.team-nav.previous').click(function(){
          if ($('.team-navigation li.active').prev('li').length < 1) {
            $('.team-navigation li.active').prev().prev('li').find('a').first().tab('show');
          } else {
            $('.team-navigation li.active').prev('li').find('a').first().tab('show');
          }
        });

        $('.dismiss-toggle').click(function(){
          $('.team-navigation li').addClass('first-opacity');
        });

        $('.back-to-top').click(function(e){
          e.preventDefault();
          $(window).scrollTo(0, 500);
        });

        $(window).scroll(function(){
          if ($(window).scrollTop() > 400) {
            $('.back-to-top').addClass('in');
          } else {
            $('.back-to-top').removeClass('in');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        setTimeout(function() {
          $('.logo-mask').addClass("faded")
          .closest('.fullscreen').addClass("collapsed");
        }, 3000);

        $(window).scroll(_.throttle(function(e) {
          height = $(".panel-grid:first-child").outerHeight();
          if ($(window).scrollTop() > height) {
            $("#bgvideo").hide();
          } else {
            $("#bgvideo").show();
          }

        }, 200));

          // var parent = $(this).closest(".panel-grid");
          //   parent.css("min-height", "120vh");

            // slide in / scroll feature on home page
          $(window).scroll(_.throttle(function () {
              $('.fade-slide').parent().each(function () {

                // if window is too narrow, display images (opacity: 1)
                if (window.innerWidth < 991) {
                    $(this).find('.fade-slide').css({'opacity': 1});
                    return true;
                }

                  var math = ( $(this).offset().top - $(window).scrollTop() ) / 1000;

                  math = 1 - math;
                  if (math >= 1) {
                      math = 1;
                      return true;
                  } else if (math <= 0) {
                      math = 0;
                  }

                  $(this).css('background-color', '#FFFFFF');

                  $(this).find('.skewed-border').css({'opacity': math});

                  var rightMargin = parseInt(math * 200, 10);
                  rightMargin = 185 - rightMargin;

                  if (rightMargin < 0) {
                    rightMargin = 0;
                  }

                  $(this).find('.skewed-border').css('padding-right', rightMargin + 'px');
              });
          }, 30));


          // Fade in contact form, tweets
          $(window).scroll(_.throttle(function () {
              $('#pg-5-7:in-viewport').addClass('fade-in').css('opacity', 1);
              $('#pg-5-8:in-viewport').addClass('fade-in').css('opacity', 1);
          }, 500));




      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'team': {
      init: function() {
        // JavaScript to be fired on the team page
        $(window).load(function(){
          $('body').on('show.bs.tab', function(event){
            $('.tab-pane').not(this).removeClass('active in');
          });
          $('body').on('shown.bs.tab', function(event){
            $(window).scrollTo($(event.target).attr('href'), 500, { offset: -100 });
          });
          if ($(window).width() < 781) {
            $('.team-navigation .tab-content').insertAfter($('.team-navigation li:nth-of-type(6)'));
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
